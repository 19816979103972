import { TitleContainer, MainTitle, SubTitle, TitleGroup } from './PageTitleStyles';
import ChatStatus from "../PageContent/ChatComponent/ChatStatus";

function PageTitle() {
    return (
        <TitleContainer>
            <TitleGroup>
                <MainTitle>Hola, ¿En qué puedo ayudarte?</MainTitle>
                <SubTitle>versión</SubTitle>
            </TitleGroup>
            <ChatStatus />
        </TitleContainer>
    );
}

export default PageTitle;
