import React from 'react';
import { LogoContainer } from './LogoStyles';

import LogoMT2 from '../../assets/images/logo-mt2.svg';
import LogoAninat from '../../assets/images/logo-aninat.svg'
function Logo() {
    return (
        <LogoContainer>
            <img src={LogoMT2} alt="Logo MT2" />
            <img src={LogoAninat} alt="Logo Aninat" />
        </LogoContainer>
    );
}

export default Logo;
