import React, { useEffect, useRef } from 'react';
import {
    BotMessage,
    MessageContainer,
    MessageWrapper,
    MessageTitle,
    UserMessage,
} from './styles/MessageAreaStyles';

const MessageArea = ({ messages , currentMessage}) => {
    const messagesEndRef = useRef(null);
    const messageContainerRef = useRef(null);

    useEffect(() => {
        if (messagesEndRef.current && messageContainerRef.current) {
            messageContainerRef.current.scrollTop =
                messagesEndRef.current.offsetTop - messageContainerRef.current.offsetTop;
        }
    }, [messages, currentMessage]);

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString('es-CL', { hour12: false, hour: '2-digit', minute: '2-digit'});
    };


    return (
        <MessageContainer ref={messageContainerRef}>
            {messages.map((message, index) => (
                <MessageWrapper key={index} isUser={message.user}>
                    <MessageTitle isUser={message.user}>
                        {message.user ? 'Tú' : 'MT2_GPT'} // {formatTime(message.timestamp)}
                    </MessageTitle>
                    {message.user ? (
                        <UserMessage>{message.text}</UserMessage>
                    ) : (
                        <BotMessage>{message.text}</BotMessage>
                    )}
                </MessageWrapper>
            ))}
            {currentMessage && (
                <div>
                    <MessageTitle isUser={false}>MT2 GPT // {formatTime(currentMessage.timestamp)} </MessageTitle>
                    <BotMessage>{currentMessage}</BotMessage>
                </div>
            )}
            <div ref={messagesEndRef} />
        </MessageContainer>
    );
};

export default MessageArea;
