import styled from 'styled-components';

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 1000px;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 16px 40px rgba(112, 144, 176, 0.2);
`;
