import React from 'react';
import Logo from './Logo/Logo';
import Menu from './Menu/Menu';
import { HeaderContainer } from './HeaderStyles';

function Header() {
    return (
        <HeaderContainer>
            <Logo />
            <Menu />
        </HeaderContainer>
    );
}

export default Header;
