import React from 'react';
import { FooterContainer, FooterText, Icon } from './styles/FooterChatStyles';
import BrandIcon from '../../../assets/images/logo-mt2-gray.svg';
const FooterChat = () => (
    <FooterContainer>
        <FooterText>Desarrollado por</FooterText>
        <a href="https://mt2.cl" target="_blank" rel="noopener noreferrer">
            <Icon src={BrandIcon} alt="MT2" />
        </a>
    </FooterContainer>
);

export default FooterChat;
