import styled from 'styled-components';

export const FooterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1.2rem;
`;

export const FooterText = styled.span`
    margin-right: 5px;
    line-height: 19.07px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.customGray};
    font-size: ${({theme}) => theme.fontSizes.regular};
`;

export const Icon = styled.img`
  width: 20px; 
  height: 20px; 
`;
