import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const MainTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.titleH1};
  font-family: ${({ theme }) => theme.fonts.bodyRegular};

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.mobileTitleH1};
  }
`;

export const SubTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({theme}) => theme.colors.secondaryGray};
  padding-left: 1rem;
  font-weight: 600;
  line-height: 16.34px;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.verySmall}; 
  }
`;

export const TitleGroup = styled.div`
  display: flex;
  align-items: baseline;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
