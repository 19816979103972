import styled from 'styled-components';

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
    height: 30px;

    @media (max-width: 600px) {
      height: 30px;
    }
  }
`;
