import React, { useState } from 'react';
import MessageArea from './MessageArea';
import InputArea from './InputArea';
import { ChatContainer } from './styles/ChatStyles';
import useChatStream from '../../../../hooks/chat/useChatStream';
import FooterChat from "./FooterChat";

const Chat = () => {
    const [input, setInput] = useState('');
    const [isBotWriting, setIsBotWriting] = useState(false);
    const { messages, currentMessage, sendMessage } =
        useChatStream(setIsBotWriting);

    const handleSend = () => {
        const trimmedInput = input.trim();
        if (!trimmedInput) {
            return;
        }
        sendMessage(input);
        setInput('');
    };

    return (
        <ChatContainer>
            <MessageArea messages={messages} currentMessage={currentMessage} />
            <InputArea
                input={input}
                setInput={setInput}
                handleSend={handleSend}
                isBotWriting={isBotWriting}
            />
            <FooterChat />
        </ChatContainer>
    );
};

export default Chat;
