import React from 'react';
import { InputContainer, Input, SendButton } from './styles/InputAreaStyles';

import SendIcon from '../../../assets/icons/input.svg';
const InputArea = ({ input, setInput, handleSend, isBotWriting }) => (
    <InputContainer>
        <Input
            placeholder="Ingresa una nueva consulta..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSend();
                }
            }}
            disabled={isBotWriting}
        />
        <SendButton onClick={handleSend} isBotWriting={isBotWriting}>
            <img src={SendIcon} alt="Send" />
        </SendButton>
    </InputContainer>
);
export default InputArea;
