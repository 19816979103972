import React from 'react';
import { StatusContainer, StatusIndicator, StatusText } from './styles/ChatStatusStyles';

function ChatStatus() {

    return (
        <StatusContainer>
            <StatusIndicator isOnline={true} />
            <StatusText>Online</StatusText>
        </StatusContainer>
    );
}

export default ChatStatus;
