import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #eee;
`;

export const Input = styled.input`
  flex-grow: 1;
  padding: 10px 20px 10px 25px;
  background-color: ${({ isBotWriting }) => (isBotWriting ? '#ccc' : '#fff')};
  color: ${({ isBotWriting }) => (isBotWriting ? '#888' : '#000')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin: 10px;

  &:focus {
    border-color: ${({ theme }) => theme.colors.colorGreen};
  }
`;

const SendButtonBase = ({ isBotWriting, ...props }) => (
    <button {...props} disabled={isBotWriting} />
);

export const SendButton = styled(SendButtonBase)`
  margin-left: 10px;
  background-color: ${({ isBotWriting }) => isBotWriting ? '#ccc' : ({theme}) => theme.colors.colorGreen};
  color: ${({ isBotWriting }) => (isBotWriting ? '#888' : '#fff')};
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ isBotWriting }) => (isBotWriting ? 'not-allowed' : 'pointer')};
  font-size: ${({theme}) => theme.fontSizes.medium};
  font-weight: 700;
`;
