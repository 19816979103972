import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 1rem 10%;


  @media (max-width: 600px) {
    padding: 10px 15px;
  }
`;
