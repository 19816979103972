import React from 'react';
import { MainSectionContainer } from './MainSectionStyles';
import PageTitle from "./PageTitle/PageTitle";
import Chat from "./PageContent/ChatComponent/Chat";

function MainSection() {
    return (
        <MainSectionContainer>
            <PageTitle />
            <Chat />
        </MainSectionContainer>
    );
}

export default MainSection;
