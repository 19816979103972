import React from 'react';
import { MenuContainer, MenuTitle, Icon } from './MenuStyles';

function Menu() {
    return (
        <MenuContainer>
            <MenuTitle>Bienvenido@</MenuTitle>
            <Icon>⋮</Icon>
        </MenuContainer>
    );
}

export default Menu;
