import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const MenuTitle = styled.span`
  margin-right: 10px;
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const Icon = styled.span`
  cursor: pointer;
  font-size: 24px;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;
