import './App.css';
import Header from "./components/Header/Header";
import AppNavigation from "./navigation/AppNavigation";
import GlobalStyles from './styles/GlobalStyles';
import {ThemeProvider} from "styled-components";
import { theme } from './styles/theme';
import { Helmet } from 'react-helmet';

const App = () => (
    <ThemeProvider theme={theme}>
        <Helmet>
            <link
                href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap"
                rel="stylesheet"
            />
        </Helmet>
        <GlobalStyles />
        <Header />
        <AppNavigation />
    </ThemeProvider>

);

export default App;
