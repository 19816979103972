import styled from 'styled-components';

const MessageWrapperBase = ({ isUser, ...props }) => (
    <div {...props} />
);

export const MessageWrapper = styled(MessageWrapperBase)`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
    margin: 10px 0;
`;
export const UserMessage = styled.div`
  align-self: flex-end;
  background-color: ${({theme}) => theme.colors.colorGreen};
  font-size: ${({theme}) => theme.fontSizes.regular};
  line-height: 19.07px;
  font-weight: 700;
  color: #fff;
  margin: 10px 0 15px 0;
  padding: 10px;
  border-radius: 8px;
  max-width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
`;
export const BotMessage = styled.div`
  background-color: ${({theme}) => theme.colors.primaryGray};
  color: ${({theme}) => theme.colors.colorBlack};
  font-size: ${({theme}) => theme.fontSizes.regular};
  line-height: 19.07px;
  font-weight: 700;
  margin: 10px 0 15px 0;
  padding: 10px;
  border-radius: 8px;
  max-width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const MessageContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 28px;
  height: 55vh;
  
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  scrollbar-width: thin;
  scrollbar-color: #f1f1f1 #f1f1f1;

`;

const MessageTitleBase = ({ isUser, ...props }) => (
    <div {...props} />
);

export const MessageTitle = styled(MessageTitleBase)`
  text-align: ${(props) => (props.isUser ? 'right' : 'left')};
  margin: 5px 0 0 0;
  font-size: ${({theme}) => theme.fontSizes.regular};
  line-height: 19.07px;
  color: ${({theme}) => theme.colors.customGray};
  font-weight: 400;
`;
