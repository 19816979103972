import { useState } from 'react';

const useChatStream = (setIsBotWriting) => {
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState('');

    const sendMessage = async (userMessage) => {

        const message = {
            text: userMessage,
            user: true,
            timestamp: Date.now()
        };

        setMessages((prevMessages) => [
            ...prevMessages,
            message,
        ]);
        setIsBotWriting(true);

        try {
            const endpoint = process.env.REACT_APP_CHAT_ENDPOINT;
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ message: userMessage }),
            });

            if (!response.ok) {
                console.error('Failed to fetch:', response.statusText);
                throw new Error('Failed to fetch');
            }

            const reader = response.body.getReader();
            let botMessageText = '';

            const readStream = async () => {
                const { done, value } = await reader.read();
                if (done) {
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        {
                            text: botMessageText,
                            user: false,
                            timestamp: Date.now()
                        },
                    ]);
                    setCurrentMessage('');
                    setIsBotWriting(false);
                    return;
                }

                botMessageText += new TextDecoder('utf-8').decode(value);
                setCurrentMessage(botMessageText);
                readStream();
            };

            await readStream();
        } catch (error) {
            console.error('Error:', error);
            setIsBotWriting(false);
        }
    };

    return { messages, currentMessage, sendMessage };
};

export default useChatStream;
