import styled from 'styled-components';

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px; 
  border: 2px solid ${({theme}) => theme.colors.colorGreen}; 
  border-radius: 20px;
  cursor: pointer;
`;

const StatusIndicatorBase = ({ isOnline, ...props }) => (
    <div {...props} />
);

export const StatusIndicator = styled(StatusIndicatorBase)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ isOnline }) => isOnline ? 'green' : 'red'};
  margin-right: 5px;
`;
export const StatusText = styled.span`
  color: ${({ theme }) => theme.colors.colorGreen };
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes.small};
  line-height: 19.07px;

`;
