import React from 'react';
import MainSection from "../components/MainSection/MainSection";

function HomePage() {
    return (
        <div>
            <MainSection />
        </div>
    );
}

export default HomePage;
