export const theme = {
    fonts: {
        bodyRegular: 'Noto Sans',
    },
    fontSizes: {
        titleH1: '26px',
        mobileTitleH1: '20px',
        regular: '14px',
        medium: '16px',
        small: '12px',
        verySmall: '8px',
    },
    colors: {
        colorBlack: '#1D1D1F',
        colorGreen: '#00995B',
        colorWhite: '#F6F8FA',
        primaryGray: '#FAFAFA',
        secondaryGray: '#8E8E93',
        customGray: '#8E8E93',
    },
};
